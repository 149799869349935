import { useEffect, useState } from 'react';
import './userLoginOrGuest.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import PaypalUi from '../../components/Payments/Paypal/paypalUi';

function UserLoginOrGuest(props) {
    const localStorageCartKey = "rafaela-duarte-store-cart";
    const localStorageClientTokenKey = "rafaela-duarte-store-token";
    const [userToken, setUserToken] = useState("");
    const [cartItems, setCartItems] = useState([]);

    return (
        <div className="user-management-page">

            <div className='choose-info'>
                <span> Escolha como pretente finalizar a compra</span>
            </div>

            <div className='login-or-create-account-or-guest'>

                <div className='login-or-create-account'>
                    <button type="button" className="btn btn-dark">Login</button>
                    <button type="button" className="btn btn-dark">Criar Conta</button>
                </div>
                <div className='login-buttons-meiota'>
                    
                </div>
                <div className='continuar-como-visitante'>
                    <button type="button" className="btn btn-dark">Continuar como Visitante</button>
                </div>

            </div>

        </div>
    );
}

export default UserLoginOrGuest;

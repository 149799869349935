export async function GetAllProducts(currencyCode, countryName) {
  const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/products`;
  const country = "brs"; //TODO: Passar o country to session storage;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: `country=${country}&currencyCode=${currencyCode}&country=${countryName}`,
  });
  const result = await response.json();
  return result;
}

export async function GetProductById(id, currencyCode, browserLanguage) {
  const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/product`;
  const country = "brs"; //TODO: Passar o country to session storage;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: `id=${id}&country=${country}&currencyCode=${currencyCode}&browserLanguage=${browserLanguage}`,
  });
  const result = await response.json();
  return result;
}

export async function GetProductVariations(id) {
  const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/product-variations`;
  const country = "brs"; //TODO: Passar o country to session storage;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: `id=${id}`,
  });
  const result = await response.json();
  return result;
}


export default GetAllProducts;


export class CartProductModel {
    constructor(name, quantity, id, category, sellPrice, photo, weight, variationId, variationName, neck, shoulder) {
      this.name = name;
      this.quantity = quantity;
      this.id = id;
      this.category = category;
      this.sellPrice = sellPrice;
      this.photo = photo;
      this.weight = weight;
      this.variationId = variationId;
      this.variationName = variationName;
      this.neck = neck;
      this.shoulder = shoulder;
    }
  }
  
  export default CartProductModel;   
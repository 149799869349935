import React from "react";
import { useEffect, useState } from "react";
import './navBar.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';


function NavBar(props) {
    const localStorageCartKey = "rafaela-duarte-store-cart";
    const localStorageCurrency = "rafaela-duarte-currency";
    // const localStorageClientTokenKey = "rafaela-duarte-store-token";
    // const [userToken, setUserToken] = useState("");
    const [userCart, setUserCart] = useState([]);
    const [cartQuantity, setCartQuantity] = useState(0);
    const [mudarCounter, setMudarCounter] = useState(false);
    const [lastItemAddedToCart, setLastItemAddedToCart] = useState(null);
    const [showAddedItem, setShowAddedItem] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState('EUR');

    useEffect(() => {
        updateCartItemsState();
        localStorage.getItem(localStorageCurrency) == null
            ? localStorage.setItem(localStorageCurrency, selectedCurrency)
            : setSelectedCurrency(localStorage.getItem(localStorageCurrency)) && props.setCurrentCurrency(selectedCurrency);
    }, [])

    useEffect(() => {
        if (props.lastItemAddedToCart != []) {

            setShowAddedItem(!showAddedItem);
            setLastItemAddedToCart(props.lastItemAddedToCart);
        }
    }, [props.lastItemAddedToCart]);

    useEffect(() => {
        setMudarCounter(!mudarCounter);

    }, [props.updateCartCount])

    useEffect(() => {
        getCartCountAndUpdate();
        if (mudarCounter == true) {
            getLastItemAddedToCart();
            setMudarCounter(!mudarCounter);
        }
    }, [mudarCounter])

    useEffect(() => {

        if (showAddedItem == true && props.lastItemAddedToCart != []) {
            setTimeout(() => {
                setShowAddedItem(!showAddedItem);
            }, 2000);
        }

    }, [showAddedItem])

    useEffect(() => {
        props.setCurrentCurrency(selectedCurrency)
    }, [selectedCurrency])

    const getCartCountAndUpdate = () => {
        updateCartItemsState();
        var totalProductsQuantity = userCart.reduce(function (previousVal, currentVal) {
            return previousVal + currentVal.quantity;
        }, 0);

        setCartQuantity(totalProductsQuantity);
    }

    const updateCartItemsState = () => {
        var cart = localStorage.getItem(localStorageCartKey) == null ? [] : JSON.parse(localStorage.getItem(localStorageCartKey));
        setUserCart(cart);
    }


    const getLastItemAddedToCart = () => {
        // if (showAddedItem) {
        var cart = localStorage.getItem(localStorageCartKey) == null ? [] : JSON.parse(localStorage.getItem(localStorageCartKey));
        setLastItemAddedToCart(cart[cart.length - 1]);
        // }
    }

    const handleChange = event => {
        localStorage.setItem(localStorageCurrency, event.target.value);
        setSelectedCurrency(event.target.value);
    }

    return (
        <div className="navbar-component" >

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <div className="navbar-logo">
                                <img src="../../assets/images/logo_rd.png" />
                            </div>
                            <li className="nav-item">
                                <Link to="/">
                                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Produção</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="produtos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Produtos
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="produtos">
                                    <li><a className="dropdown-item" href="#">Bolsas</a></li>
                                    <li><a className="dropdown-item" href="#">Vestuário</a></li>
                                    <li><a className="dropdown-item" href="#">Acessórios</a></li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
            <div className="navbar-right-side">
                <div className="currency">
                    <div className="currency-dropdown">

                        <select class="form-select" aria-label="Default select example" onChange={handleChange}>
                            {/* <option selected>{selectedCurrency}</option> */}
                            <option value="EUR" selected={selectedCurrency == "EUR"}>€</option>
                            <option value="BRL" selected={selectedCurrency == "BRL"}>R$</option>
                            <option value="USD" selected={selectedCurrency == "USD"}>$</option>
                        </select>
                    </div>
                </div>
                <div className="shopping-card-section">
                    <Link to="cart">
                        <img className="shopping-bag" src="../../../assets/icons/bag2.svg" />
                        {cartQuantity}
                    </Link>
                    {showAddedItem ?
                        <div className="item-added-to-cart">
                            <div className="item-added-details">

                                <div className="prd-name">
                                    {lastItemAddedToCart?.name}
                                </div>
                                <div className="img-container">
                                    <img src={lastItemAddedToCart?.photo} />
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>

        </div>
    );

}
export default NavBar;
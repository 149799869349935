import React, { Component, useEffect, useState } from "react";

import ReactImageMagnify from "react-image-magnify";

function MyReactImageMagnify(props) {

  const photo = props.photoUrl;

  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          alt: "Wristwatch by Ted Baker London",
          isFluidWidth: false,
          height: 500,
          width: 500,
          sizes: 'M',
          src: props.photoUrl,
        },
        largeImage: {
          src: props.photoUrl,
          width: 1200,
          height: 1200
        },
        enlargedImagePortalId: "myPortal"
      }}
    />
  );
}


export default MyReactImageMagnify;

import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import MyReactImageMagnify from "./MyReactImageMagnify";

function MyImageGallery(props) {
    const myRenderItem = (e) => {
        return <MyReactImageMagnify photoUrl={e.original} />;
    }

    const properties = {
        thumbnailPosition: 'bottom',
        useBrowserFullscreen: false,
        showPlayButton: false,
        renderItem: myRenderItem.bind(this),
        items: props.productImages,
        showFullscreenButton:false
    };

    return (<ImageGallery {...properties} />);

}
export default MyImageGallery;

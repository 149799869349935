import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetProductById,GetProductVariations } from '../Home/api/productApi';
import './productDetai.css';
import React from "react";
import Grid from "@material-ui/core/Grid";
import "react-image-gallery/styles/css/image-gallery.css";
import CartProductModel from './CartProductModel';

import MyImageGallery from "../../components/MyImageGallery";
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

function ProductDetail(props) {
  const {t, i18n} = useTranslation();
  const localStorageCartKey = "rafaela-duarte-store-cart";
  const baseImagePath = '../../assets/product-images';
  const localStorageClientTokenKey = "rafaela-duarte-store-token";
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [productImages, setProductImages] = useState([]);
  const [counter, setCounter] = useState(1);
  const [country, setCountry] = useState("");
  const [variations, setVariations] = useState({});  
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [neckCircunference, setNeckCircunference] = useState(0);
  const [shoulderMeasure, setShoulderMeasure] = useState(0);
  const params = useParams();

  const getProductVariations = async () => {
    const res = await GetProductVariations(params.productId);
    let groupedVariations =  Object.groupBy(res, ({ type }) => type);
    setVariations(groupedVariations);
  }

  const getProductById = async () => {
    const currencyCode = getCurrencyCode();
    const browserLanguage = navigator.language;
    const res = await GetProductById(params.productId, currencyCode, browserLanguage);
    setProduct(res);
    let arrayOfPhotos = [];
    res.photos.map(e => arrayOfPhotos.push({ original: `${baseImagePath}/${e}`, thumbnail: `${baseImagePath}/${e}` }));
    setProductImages(arrayOfPhotos);
    setIsLoading(false);
  }

  useEffect(() => {
    const country = getCountry();
    getProductById();
    getProductVariations();
  }, [, props.currentCurrency]);

  const updateCounter = (number) => {
    if (number >= 1) {
      setCounter(number)
    }
  }

  const addToCart = () => {
    if(variations?.Size && (selectedVariation == 'Size' || selectedVariation == null)){
      alert(`${t('productDetail.selectTheSize')}`);
      return;
    }

    if(product.stockTypeId == "3" && (neckCircunference == 0 || shoulderMeasure == 0)){
      alert(`${t('productDetail.insertTheMeasures')}`);
      return;
    }

    let sizeVariationName = variations.Size?.filter(e => e.id == selectedVariation)[0]?.variation ?? '';

    let cartOnLocalStorage = localStorage.getItem(localStorageCartKey);

    if (cartOnLocalStorage != null) {
      let cartJson = JSON.parse(cartOnLocalStorage);
      let cartProduct = new CartProductModel(product.name, counter, product.id, 
        product.category, product.basePrice, productImages[0].original, product.weigth, selectedVariation,
        sizeVariationName, neckCircunference, shoulderMeasure)
      props.setLastItemAddedToCart(cartProduct);
      cartJson.push(cartProduct);
      localStorage.setItem(localStorageCartKey, JSON.stringify(cartJson));

    } else {

      cartOnLocalStorage = [];
      let cartProduct = new CartProductModel(product.name, counter, product.id, 
        product.category, product.basePrice, productImages[0].original, product.weigth, selectedVariation,
        sizeVariationName, neckCircunference, shoulderMeasure);
      props.setLastItemAddedToCart(cartProduct);
      cartOnLocalStorage.push(cartProduct);
      localStorage.setItem(localStorageCartKey, JSON.stringify(cartOnLocalStorage));
    }

    if (isUserLoggedIn()) {
      //TODO: encontrar o carrinho do user no BE e inserir ou atualizar;
    }

    props.setUpdateCartCount();
  }

  const addToCartV2 = () => {

  }

  const getCountry = () => {
    let localVariable = localStorage.getItem('country') ?? '*';
    setCountry(localVariable);
    return localVariable;
  }

  const isUserLoggedIn = () => {
    let token = localStorage.getItem(localStorageClientTokenKey);
    if (token == null) return false;

    return isValidToken(token);
  }

  const isValidToken = (token) => {
    //TODO: checar se o token está dentro do período de validade
    return true;
  }

  const getCurrencyCode = () => {
    let localVariable = localStorage.getItem('rafaela-duarte-currency') ?? 'EUR';
    return localVariable;
  }

  const confirmationProductionTimeModal = () => {
    // if (1 == 1) {
    //   alert("é sim");
    // }

    return (<div class="modal fade" id="productionTimeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">

          <div class="modal-body">
            Este produto é produzido na Europa, por essa razão, se faz necessário pagamento de R$15,00 para o Desalfandegamento
            do produto, no site dos Correios:
            Saiba mais no link: <a target="_blank" href='https://www.correios.com.br/receber/encomenda/saiba-mais-internacional'>
              https://www.correios.com.br/receber/encomenda/saiba-mais-internacional
            </a>
            
            Dado que será um envio internacional, o tempo para recebimento deste produto poderá ser maior do que o habitual, 
            e/ou poderá sobrer taxação de impostos de importação.

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-dark" data-bs-dismiss="modal" onClick={() => addToCart()}>Estou ciente</button>
          </div>
        </div>
      </div>
    </div>)
  }


  return (
    <Suspense fallback="...loading">
      {isLoading ?
        <></>
        :
        <div className='product-page'>
          {confirmationProductionTimeModal()}
          <div className='product-detail-name'>{product.name}</div>
          <div className='product-detail-section'>
            <div className='photos-galery'>
              <Grid container spacing={1}>
                {/* <Grid item xs={6}> */}
                <MyImageGallery productImages={productImages} />
                {/* </Grid> */}
                {/* <Grid container spacing={2} item xs={6} direction="column">
              <Grid item> */}
                <div id="myPortal" />
                {/* </Grid>
            </Grid> */}
              </Grid>


            </div>
            <div className='product-details'>
              <h2> {product.CurrencySymbol} {Number(product.sellPrice).toFixed(2)} </h2>
              {product.description}{product.description}{product.description}{product.description}{product.description} <br />
              {product.description}{product.description}{product.description}{product.description}{product.description} <br />
              <br />
              {product.description}{product.description}{product.description}{product.description}{product.description} <br />

              {variations != [] &&
                <div className='variations'>
                 {'Size' in variations && 
                  <div className='size-variations'>
                    <div className='variation-name'>{t('productDetail.size')}</div>
                    <div class="form-group">
                              <select id="sizeVariation" class="form-control" 
                              onChange={(e) => setSelectedVariation(e.target.value)}
                              >
                                  <option selected>{t('productDetail.SelectOnOption')}</option>
                                  {variations.Size.map(e => {
                                      return (<option value={e.id}>{e.variation}</option>)
                                  })}
                              </select>
                          </div>
                  </div>
                  }
              </div>
              }

              {product.stockTypeId == "3" && 
                <div className='medidas'>

                  <div className='medida-neck'>
                    <div className='variation-name'>{t('productDetail.neckCircunference')}</div>
                      <div class="form-group">
                            <input id="sizeVariation" type='number' class="form-control" 
                            onChange={(e) => setNeckCircunference(e.target.value)}/>
                      </div>
                    </div>
                    <div className='medida-shoulder'>
                    <div className='variation-name'>{t('productDetail.shoulderMeasure')}</div>
                      <div class="form-group">
                            <input id="sizeVariation" type='number' class="form-control" 
                            onChange={(e) => setShoulderMeasure(e.target.value)}/>
                      </div>
                    {t('productDetail.infoMeasuredInCm')}
                    </div>
                </div>
                
              
              }
              <div className='adicionar-cart-section'>

                <div className='counter'>
                  <div onClick={() => updateCounter(counter - 1)}>-</div>
                  <div>{counter}</div>
                  <div onClick={() => updateCounter(counter + 1)}>+</div>
                </div>

                <div className='adicionar-cart-section-button'>
                  <button type="button" class="btn btn-dark" data-bs-toggle={(country == 'Brazil' && product.CountryAvailable == '*') ? "modal" : ""}
                    data-bs-target={(country == 'Brazil' && product.CountryAvailable == '*') ? "#productionTimeModal" : ""}
                    className="button-site-default"
                    onClick={() => country == 'Brazil' && product.CountryAvailable == '*'
                      ? confirmationProductionTimeModal()
                      : addToCart()}>{t('productDetail.add')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </Suspense>
  )
}

export default ProductDetail;
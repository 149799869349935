export async function GetAllCountries() {
    const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/geo-countries`;
    const country = "brs"; //TODO: Passar o country to session storage;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        //   body: `country=${country}&currencyCode=${currencyCode}`,
    });
    const result = await response.json();
    return result;
}

export async function GetShippingPrice(countryId, isIsland, totalWeight) {
    const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/calculate-shipping-price`;
    const country = "brs"; //TODO: Passar o country to session storage;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: `countryId=${countryId}&isIsland=${isIsland}&totalWeight=${totalWeight}`,
    });
    const result = await response.json();
    return result;
}


export async function GetUpdatedCheckoutItemPrices(quantity) {
    const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/production-time`;
    const country = "brs"; //TODO: Passar o country to session storage;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: `quantity=${quantity}`,
    });
    const result = await response.json();
    return result;
}


export async function GetAvailableDates() {
    const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/available-dates`;
    const country = "brs"; //TODO: Passar o country to session storage;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        }
    });
    const result = await response.json();
    return result;
}

export async function SaveOrder(name, email, address1, address2, city, state, countryId, zipCode, shippingPrice, currencyId, orderid, products, currentProductionDate) {
    const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/save-order`;
    const country = "brs"; //TODO: Passar o country to session storage;

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name: name,
            email: email,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            countryid: countryId,
            zipcode: zipCode,
            shippingprice: shippingPrice,
            currencyid: currencyId,
            orderid: orderid,
            products: products,
            agreedShippingDate: currentProductionDate
        }),
    });
    const result = await response.json();
    return result;
}

export default GetAllCountries;

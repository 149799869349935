import { useEffect } from "react";
import CarouselBanner from "./carouselBanner";
import Products from "./products";

function Home(props) {

  useEffect(() => {
  }, [props.currentCurrency])
  return (
    <div className="products-holder">
      <CarouselBanner />
      <Products currentCurrency={props.currentCurrency}/>
    </div>
  );
}

export default Home;

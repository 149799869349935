import { useEffect, useState } from "react";
import { GetAllProducts } from '../Home/api/productApi';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


function Products(props) {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getProducts = async () => {
        const currencyCode = getCurrencyCode();
        const country = getCountry();
        const res = await GetAllProducts(currencyCode, country);
        let result = [...res
            // , res[0], res[1]
        ]
        setProducts(result);
        console.log(result.filter(e => e.CountryName == 'Brazil').length)
    }

    useEffect(() => {
        getProducts();
    }, [, props.currentCurrency]);

    useEffect(() => {
        // console.log(products);
    }, [isLoading])

    const getCurrencyCode = () => {
        let localVariable = localStorage.getItem('rafaela-duarte-currency') ?? 'EUR';
        return localVariable;
    }

    const getCountry = () => {
        let localVariable = localStorage.getItem('country') ?? '*';
        return localVariable;
    }

    const getCountryLabels = (labelName) => {
        return (<div className="available-country-label-section">
            <br/>
            <div className="available-country-label">
                <h3 className="h3"><span class="line-center">{labelName}</span> </h3>
            </div>
        </div>);
    }

    return (
        <div>
            <div>
                {products?.filter(e => e.CountryAvailable == 'Brazil').length > 0
                    ? null//getCountryLabels("Exclusivos Brasil")
                    : null}
                <div className="products-section">
                    {products && products?.filter(e => e.CountryAvailable == 'Brazil').map((e, key) => (
                        <Link key={key} to={`/product/${e.category}/${e.id}`}>
                            <div className="product-box" key={key}>
                                <div className="image">
                                    <img src={1 == 2 ? `assets/product-images/thumbnails/${e?.Thumbnail}` : `assets/product-images/top-mares-perola-1.jpg`} className="mg-fluid" />
                                </div>
                                <div className="product-name-price">
                                    <span className="product-name">
                                        {e.name}
                                    </span>
                                    <span className="product-price">
                                        {e.CurrencySymbol}{e.sellPrice}
                                    </span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>


                {products?.filter(e => e.CountryAvailable == 'Brazil').length > 0 
                    ? null //getCountryLabels("Produzidos na Europa")
                    : null}
                <div className="products-section">

                    {products && products?.filter(e => e.CountryAvailable == '*').map((e, key) => (
                        <Link key={key} to={`/product/${e.category}/${e.id}`}>
                            <div className="product-box" key={key}>
                                <div className="image">
                                    <img src={1 == 1 ? `assets/product-images/thumbnails/${e.photo}` : `assets/product-images/top-mares-perola-1.jpg`} className="mg-fluid" />
                                </div>
                                <div className="product-name-price">
                                    <span className="product-name">
                                        {e.name}
                                    </span>
                                    <span className="product-price">
                                        {e.CurrencySymbol}{e.sellPrice}
                                    </span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Products;
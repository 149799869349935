import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/home';
import Footer from './Pages/Common/Footer/footer';
import ProductDetail from './Pages/productDetail/productDetail';
import NavBar from './components/navBar/navBar';
import { useEffect, useState } from 'react';
import Cart from './Pages/Cart/cart';
import Checkout from './Pages/Checkout/checkout';
import UserLoginOrGuest from './Pages/userLoginOrGuest/userLoginOrGuest';

function App() {
  const [updateCartCount, setUpdateCartCount] = useState(false);
  const [lastItemAddedToCart, setLastItemAddedToCart] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState('');

  useEffect(() => {
    setUpdateCartCount(!updateCartCount);
  }, []);

  setBrowserCulture()
  return (
    <div className="App">
      <Router>
        <NavBar
          updateCartCount={!updateCartCount}
          lastItemAddedToCart={lastItemAddedToCart}
          setCurrentCurrency={(currency) => setCurrentCurrency(currency)} />
        <br />
        <br />
        <br />
        {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
               
                <Link to="/users/4200">Users</Link>
              </li>
              <li>
              
                <Link to="/productdeteail">product detauls</Link>
              </li>
            </ul>
          </nav> */}

        <Routes>
          <Route path="/" element={<Home currentCurrency={currentCurrency} />} />
          <Route path="/home" element={<Home currentCurrency={currentCurrency} />} />
          <Route path="/product/:category/:productId" element={
            <ProductDetail
              setUpdateCartCount={() => setUpdateCartCount(!updateCartCount)}
              setLastItemAddedToCart={(newItem) => setLastItemAddedToCart(newItem)}
              currentCurrency={currentCurrency} />} />
          <Route path="/cart" element={<Cart currentCurrency={currentCurrency} />} />
          <Route path="/checkout" element={<Checkout currentCurrency={currentCurrency} />} />
          <Route path='/login' element={<UserLoginOrGuest />} />

          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found etc</h2>
              </div>
            }
          />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;


async function setBrowserCulture() {
  if (localStorage.getItem("country") === null) {
    const response = await fetch("https://get.geojs.io/v1/ip/geo.js");
    const result = await response.text();
    let frase = result;
    let novaFrase = frase.replace(/geoip\(|\)/g, '');
    let country = JSON.parse(novaFrase).country
    localStorage.setItem('country', country);
  }

}
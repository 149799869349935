import { PayPalScriptProvider, PayPalButtons, PayPalMarks } from "@paypal/react-paypal-js";

import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

export function PaypalUi(props) {
  const {t, i18n} = useTranslation();
  const client_id = process.env.REACT_APP_PAYPAL_Client_ID;
  const amount = props.totalCarrinho;
  const currency = props.currentCurrency;

  return (
    <div>
      <button hidden={true} id="openModalHidden" data-bs-toggle="modal" data-bs-target="#orderDone">Salvar e continuar</button>

      <div class="modal fade" id="orderDone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{t("payment.thankForYourPurchase")}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
             {t("payment.yourOrderIsInProcessing")}
            </div>
          </div>
        </div>
      </div>

      <PayPalScriptProvider
        options={{
          clientId: client_id,
          components: "buttons,marks,funding-eligibility",
          currency: currency
        }}>
        <PayPalButtons
          forceReRender={[amount, currency]}
          createOrder={(data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: amount,
                    },
                  },
                ],
              })
              .then((orderId) => {
                // Your code here after create the order
                // setTimeout(() => alert("order criada"), 2000)
                // console.log("orderId", orderId);
                // alert(orderId);

                props.doSaveOrder(orderId);
                return orderId;
              });
          }}
          onApprove={function (data, actions) {
            return actions.order.capture().then(function () {
              // Your code here after capture the order
              // setTimeout(() => alert("pagamento capturado"), 2000)
              // console.log("capturado-data", data);
              // console.log("capturado-actions", actions);

              let modal = document.getElementById('openModalHidden');
              modal.click();

              setTimeout(() => {
                localStorage.setItem('rafaela-duarte-store-cart', '[]')
                window.location.href = "/";
              }, 1000)


            });
          }}

        />
      </PayPalScriptProvider>
    </div>
  );
}

export default PaypalUi;

import { useEffect, useState } from 'react';
// import './Cart.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import PaypalUi from '../../components/Payments/Paypal/paypalUi';
import UserLoginOrGuest from '../userLoginOrGuest/userLoginOrGuest';
import './Checkout.css';
import { GetAllCountries, GetShippingPrice, SaveOrder, GetUpdatedCheckoutItemPrices, GetAvailableDates } from './api/CheckoutApi';
import { GetAllExchangeRates } from '../Cart/api/CartApi';

import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

function Checkout(props) {
    const {t, i18n} = useTranslation();
    const localStorageCartKey = "rafaela-duarte-store-cart";
    const localStorageClientTokenKey = "rafaela-duarte-store-token";
    const [userToken, setUserToken] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [showPurchaseSummary, setShowPurchaseSummary] = useState(false);
    const [shippingPanelOpen, setShippingPanelOpen] = useState(true);
    const [summaryPanelOpen, setSummaryPanelOpen] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedState, setSelectedState] = useState("");
    const [shippingPrice, setShippingPrice] = useState(0);
    const [isPossibleToCalculate, setIsPossibleToCalculate] = useState(true);
    const [rateByCurrency, setRateByCurrency] = useState(0);
    const [exchangeRates, setExchangeRates] = useState([]);
    //form
    const [shippingName, setShippingName] = useState("");
    const [shippingEmail, setShippingEmail] = useState("");
    const [shippingAddres1, setShippingAddres1] = useState("");
    const [shippingAddress2, setShippingAddress2] = useState("");
    const [shippingCity, setShippingCity] = useState("");
    // const [shippingCountryId, setShippingCountryId] = useState("");
    const [shippingState, setShippingState] = useState("");
    const [shippingPortugalIsland, setShippingPortugalIsland] = useState("Continente");
    const [shippingZipCode, setShippingZipCode] = useState("");
    const [shippingCost, setShippingCost] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [orderId, setOrderId] = useState(0);
    const [currentProductionDate, setCurrentProductionDate] = useState('');
    const [currentDaysRatio, setCurrentDaysRatio] = useState('');

    const PORTUGAL_ID = 179;

    useEffect(() => {
        getExchangeRates();
        getCountries();
        getCart();
        getCurrentProductionTime();
        //TODO: it no backend e pegar os valores atualizados dos produtos x quantidades para por no total
    }, [])

    useEffect(() => {
        var result = exchangeRates.filter(e => e.Code == `${props.currentCurrency}`)[0]?.ConversionFromEuro;
        if (result != null) {
            setRateByCurrency(result);
            setShippingCost(shippingPrice * rateByCurrency);
        }
        getCart();
    }, [props.currentCurrency, rateByCurrency, exchangeRates])


    const getTotalWeight = () => {
        return cartItems.reduce(function (previousVal, currentVal) {
            return previousVal + (Number(currentVal.weight) * currentVal.quantity);
        }, 0);
    }

    useEffect(() => {
        var peso = getTotalWeight();
        setTotalWeight(peso);
    }, [cartItems])

    const getCountries = async () => {
        const res = await GetAllCountries();
        setCountries(res);
    }

    const getCart = () => {
        var cart = localStorage.getItem(localStorageCartKey) == null
            ? []
            : JSON.parse(localStorage.getItem(localStorageCartKey));

        setCartItems(getDistinctProducts(cart));
    }

    useEffect(() => {
        var peso = getTotalWeight();
        setTotalWeight(peso);
    }, [totalWeight])

    const getShippingPriceOnChangeCountry = async (e) => {
        var peso = getTotalWeight();
        setTotalWeight(peso);

        setSelectedCountry(e);
        const res = await getShipp(e, false, totalWeight);
        if (res[0].price != null) {
            setShippingPrice(res[0].price);
            setIsPossibleToCalculate(true);
            setShippingCost(Number(res[0].price) * rateByCurrency);

        } else {
            setIsPossibleToCalculate(false);
        }
    };

    const getShipp = async (countryId, falsess, totalWight) => {
        return await GetShippingPrice(countryId, falsess, totalWight)
    };

    const DoSaveOrder = async (providerOrderId) => {
        let products = [];
        let currencyId = props.currentCurrency == 'EUR' ? 1
            : props.currentCurrency == 'BRL' ? 2
                : 3;

        cartItems?.map(e => products.push({ productId: e.id, quantity: e.quantity, 
            variationId: e.variationId ? e.variationId : null, 
                details: (+e.neck && +e.shoulder) 
                    ? `Neck: ${e?.neck} cm | Shoulder: ${e?.shoulder} cm`
                    : null }))

                await SaveOrder(shippingName, shippingEmail, shippingAddres1, shippingAddress2, 
            shippingCity, shippingState, selectedCountry, shippingZipCode, shippingCost, currencyId, providerOrderId, products, currentProductionDate)
            .then(e => setOrderId(e))
            .catch(e => alert("algum erro ocorreu!"));
    }

    // useEffect(() => {
    // }, [orderId])
    // useEffect(() => {
    //     setUserToken(getUserToken());
    //     if (userToken == '') {
    //         var cart = localStorage.getItem(localStorageCartKey) == null
    //             ? []
    //             : JSON.parse(localStorage.getItem(localStorageCartKey));

    //         setCartItems(getDistinctProducts(cart));
    //     } else {
    //         // TODO: vai ao banco buscar o carrinho
    //     }
    // }, []);


    // const getDistinctProducts = (arrayOfProducts) => {
    //     let result = [];
    //     arrayOfProducts.forEach(function (a) {
    //         if (!this[a.id]) {
    //             this[a.id] = { id: a.id, name: a.name, quantity: a.quantity, category: a.category, sellPrice: Number(a.sellPrice) };
    //             result.push(this[a.id]);
    //         } else {
    //             this[a.id].quantity += a.quantity;
    //             this[a.id].total += this[a.id].quantity * Number(this[a.id].sellPrice);
    //         }
    //     }, Object.create(null));

    //     return result;
    // }

    // const getUserToken = () => {
    //     return localStorage.getItem(localStorageClientTokenKey) == null ?? '';
    // }

    const getExchangeRates = async () => {
        await GetAllExchangeRates().then(e => {
            setExchangeRates(e);
            getTotalCartValue();
        });
    }

    const showPaymentMethodsSection = () => {
        setShippingPanelOpen(false);
        setShowPaymentMethods(true)
        // if (1 == 1) { //se o endereço ta no session storage
        //     setShowPaymentMethods(true)
        // }
    }

    const reverCompra = () => {
        if (1 == 1) { //se o endereço ta no session storage
            setShowPaymentMethods(true)
        }
    }

    const abrePanel = () => {

        var coll = document.getElementsByClassName("collapsible");
        var i;

        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var content = this.nextElementSibling;
                if (content.style.display === "block") {
                    content.style.display = "none";
                } else {
                    content.style.display = "block";
                }
            });
        }
    }

    const openPanel = (className) => {

        setShippingPanelOpen(!shippingPanelOpen);
        return;
        var coll = document.getElementsByClassName(className)[0];
        if (coll.style.display === "block") {
            coll.style.display = "none";
        } else {
            coll.style.display = "block";
        }
    }

    const getDistinctProducts = (arrayOfProducts) => {
        let result = [];
        arrayOfProducts.forEach(function (a) {
            // if (!this[a.id]) {
                this[a.id] = { id: a.id, name: a.name, quantity: a.quantity, category: a.category, 
                    sellPrice: Number(a.sellPrice) * Number(rateByCurrency), photo: a.photo, weight: a.weight,
                    variationName: a.variationName, variationId: a.variationId, neck: a.neck, shoulder: a.shoulder };
                result.push(this[a.id]);
            // } else {
            //     this[a.id].quantity += a.quantity;
            //     this[a.id].total += this[a.id].quantity * Number(this[a.id].sellPrice);
            // }
        }, Object.create(null));

        return result;
    }

    const getTotalCartValue = () => {
        return cartItems.reduce(function (previousVal, currentVal) {
            return previousVal + (Number(currentVal.quantity) * currentVal.sellPrice.toFixed(2));
        }, 0);
    }


    const confirmationProductionTimeModal = () => {
        let isValid = isValidForm();
        return (<div class="modal fade" id="productionTimeModal"  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">

                {isValid == true && currentProductionDate != ''? 
                    <>
                        <div class="modal-body">
                            {t("checkout.shippingForm.validation.availableDate")} <b> {currentProductionDate}. </b>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-dark" data-bs-dismiss="modal" onClick={() => showPaymentMethodsSection()}> {t("checkout.shippingForm.validation.iAmOk")}</button>
                        </div>
                    </>
                    :
                    <>
                    <div class="modal-body">
                       {t("checkout.shippingForm.validation.fillFormFields")}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Ok</button>
                    </div>
                </>
                    }
                </div>
            </div>
        </div>)
    }


    const isValidForm = () => {
        if(selectedCountry == "34" && selectedState == "") return false; 

        if(shippingName == "" || shippingEmail == "" || shippingAddres1 == "" || shippingCity == "" || 
        selectedCountry == "" || shippingZipCode == ""){
            return false;
        }

        if(selectedCountry == PORTUGAL_ID && shippingPortugalIsland == "") return false;

        return true;
    }

    const getCurrentProductionTime = () => {
        //TODO: ajustar aqui
        if (currentProductionDate == '') {
            GetUpdatedCheckoutItemPrices() 
                .then(e => {
                    var cardItemsQuantity = cartItems?.map(e => e.quantity).reduce((accumulator, currentValue) => {
                        return accumulator + currentValue},0);
                    let totalitems = (Number(e.result) +  Number(cardItemsQuantity));

                    const deliveryDates = [
                        '2024-01-26',
                        '2024-02-23',
                        '2024-03-29',
                        '2024-04-26',
                        '2024-05-31',
                        '2024-06-28',
                        '2024-07-26',
                        '2024-08-30',
                        '2024-09-27',
                        '2024-10-25',
                        '2024-11-29',
                        '2025-01-31',
                        '2025-02-28',
                        '2025-03-28',
                        '2025-04-25',
                        '2025-05-30',
                        '2025-06-27',
                        '2025-07-25',
                        '2025-08-29',
                        '2025-09-26',
                        '2025-10-31',
                        '2025-11-28',
                        '2026-01-30',
                        '2026-02-27',
                        '2026-03-27',
                        '2026-04-24',
                        '2026-05-29',
                        '2026-06-26',
                        '2026-07-31',
                        '2026-08-28',
                        '2026-09-25',
                        '2026-10-30',
                        '2026-11-27',
                        '2026-12-25',
                        '2027-01-29',
                        '2027-02-26',
                        '2027-03-26',
                        '2027-04-30',
                        '2027-05-28',
                        '2027-06-25',
                        '2027-07-30',
                        '2027-08-27',
                        '2027-09-24',
                        '2027-10-29',
                        '2027-11-26',
                    ];

                    // Obter a data atual
                    const currentDate = new Date();

                    // Filtrar as datas que são maiores que a data atual
                    const futureDates = deliveryDates.filter(dateString => new Date(dateString) > currentDate);

                    var quantidadeDeCiclos = Math.ceil(totalitems/8)
                    // Ordenar as datas futuras em ordem crescente
                    futureDates.sort((a, b) => new Date(a) - new Date(b));


                    if(currentDate.getDay() > 15){
                        quantidadeDeCiclos += 1;
                    }

                    // Obter a primeira data na lista de datas futuras
                    const nextDate = futureDates[quantidadeDeCiclos];

                    if(nextDate != undefined){
                        setCurrentProductionDate(nextDate);
                    }
                    console.log(nextDate); // Exibe a próxima data maior que a data atual

                })
        }
    }


const colapsedOrderSummary = () => {
    return (
        <div className='colapsedMenu' onClick={() => setSummaryPanelOpen(!summaryPanelOpen)}>
            <div className='colapsedMenu-title'>
                <div className='sumary-title'>
                    <span className='checkout-title'>{t('checkout.inYourBag')}</span>
                    {!summaryPanelOpen
                                ? <img className="shopping-bag" src="../../../assets/icons/chevron-down-line.svg" />
                                : <img className="shopping-bag" src="../../../assets/icons/chevron-up-line.svg" />}
                </div>
                <div className='sumary-count-value'>
                    <div> ({cartItems?.map(e => e.quantity).reduce((accumulator, currentValue) => {
                                    return accumulator + currentValue},0)} {t("checkout.items")})</div>
                </div>

            </div>

            {summaryPanelOpen && 
            <div className='colapsedManu-items'>
                {cartItems.map(e => {
                    return (<>
                        <div className='colasedMenu-item-box'>
                            <div>
                                <img className='item-cart-image' src={e.photo} />
                            </div>
                           <div className='colapsed-item-details'>
                                {e.quantity} x {e.name} {e.variationName ? ` - ${e.variationName}` : ''}
                            </div> 
                        </div>

                        <hr />
                    </>)
                })}

                <div>
                    {t('checkout.products')}: {Number(getTotalCartValue()).toFixed(2)} {props.currentCurrency}
                    <br />
                    {t('checkout.shipping')}:  {shippingPrice} {props.currentCurrency}
                    <br />
                    Total: {(Number(getTotalCartValue()) + Number(shippingPrice)).toFixed(2)}
                </div>

            </div>
            }
        </div>
    )
}

    return (
        <Suspense fallback="...loading">
        <div className="checkout-page">
            {confirmationProductionTimeModal()}
            <div className='form-central checkout-form'>

                {colapsedOrderSummary()}

                <div onClick={() => setShippingPanelOpen(!shippingPanelOpen)}>
                    <span className='checkout-title'>{t('checkout.shipping')}</span>
                    {!shippingPanelOpen
                        ? <img className="shopping-bag" src="../../../assets/icons/chevron-down-line.svg" />
                        : <img className="shopping-bag" src="../../../assets/icons/chevron-up-line.svg" />}

                </div>
                {shippingPanelOpen &&
                    <div className='shipping-options'>
                        <form>
                            <br /><br />
                            <div class="form-row">
                                <div class="form-group">
                                    <input type="text" value={shippingName} required class="form-control" id="inputName" placeholder={t('checkout.shippingForm.name')} onChange={(e) => setShippingName(e.target.value)} />

                                </div>
                                <div class="form-group">
                                    <input type="email" value={shippingEmail} class="form-control" id="inputEmail4" placeholder="Email" onChange={(e) => setShippingEmail(e.target.value)} />
                                </div>
                            </div>

                            <div class="form-group">
                                <input type="text" value={shippingAddres1} class="form-control" id="inputAddress" placeholder={t('checkout.shippingForm.shippingAddress')} onChange={(e) => setShippingAddres1(e.target.value)} />
                            </div>
                            <div class="form-group">
                                <input type="text" value={shippingAddress2} class="form-control" id="inputAddress2" placeholder={t('checkout.shippingForm.complement')} onChange={(e) => setShippingAddress2(e.target.value)} />
                            </div>
                            <div class="form-row">
                                <div class="form-group">
                                    <input type="text" value={shippingCity} class="form-control" id="inputCity" placeholder={t('checkout.shippingForm.city')} onChange={(e) => setShippingCity(e.target.value)} />
                                </div>

                                {props.currentCurrency == "BRL" &&
                                    <div class="form-group col-md-4">
                                        <label for="inputState">Estado</label>
                                        <select id="inputState" value={selectedState} class="form-control" onChange={(e) => setSelectedState(e.target.value)}>
                                            <option selected>Choose...</option>
                                            <option value="AC">AC</option>
                                            <option value="AL">AL</option>
                                            <option value="AP">AP</option>
                                            <option value="AM">AM</option>
                                            <option value="BA">BA</option>
                                            <option value="CE">CE</option>
                                            <option value="ES">ES</option>
                                            <option value="GO">GO</option>
                                            <option value="MA">MA</option>
                                            <option value="MT">MT</option>
                                            <option value="MS">MS</option>
                                            <option value="MG">MG</option>
                                            <option value="PA">PA</option>
                                            <option value="PB">PB</option>
                                            <option value="PR">PR</option>
                                            <option value="PE">PE</option>
                                            <option value="PI">PI</option>
                                            <option value="RJ">RJ</option>
                                            <option value="RN">RN</option>
                                            <option value="RS">RS</option>
                                            <option value="RO">RO</option>
                                            <option value="RR">RR</option>
                                            <option value="SC">SC</option>
                                            <option value="SP">SP</option>
                                            <option value="SE">SE</option>
                                            <option value="TO">TO</option>
                                            <option value="DF">DF</option>
                                        </select>
                                    </div>
                                }
                                <div class="form-group">
                                    <select id="inputCountry" value={selectedCountry} class="form-control" placeholder={t('checkout.shippingForm.city')} onChange={(e) => getShippingPriceOnChangeCountry(e.target.value)}>
                                        <option selected>{t('checkout.shippingForm.country')}</option>
                                        {countries && countries.filter(e => e.id !=1).map(e => {
                                            return (<option value={e.id}>{e.name}</option>)
                                        })}
                                    </select>
                                </div>
                                {selectedCountry == PORTUGAL_ID &&
                                    <div class="form-group">
                                        <label for="inputIslandOrContinent">Continente ou Ilhas?</label>
                                        <select id="inputIslandOrContinent" value={shippingPortugalIsland} class="form-control" onChange={(e) => setShippingPortugalIsland(e.target.value)}>
                                            <option selected>Continente</option>
                                            <option>Açores</option>
                                            <option>Madeira</option>

                                        </select>
                                    </div>
                                }
                                <div class="form-group">
                                    <input type="text" value={shippingZipCode} class="form-control" id="inputZip" placeholder={t("checkout.zipCode")} onChange={(e) => setShippingZipCode(e.target.value)} />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-check">
                                    {/* <input class="form-check-input" type="checkbox" id="gridCheck" />
                                    <label class="form-check-label" for="gridCheck"> */}
                                        {/* Check me out {!isPossibleToCalculate && 'Nao da pra calcular'} TODO Não lembro pq existe essa lógica*/} 
                                        {/* <br></br>
                                        valor do envio {shippingPrice} - {shippingCost} */}
                                    {/* </label> */}
                                </div>
                            </div>
                        </form>

                        {totalWeight > 2000 && 'pesado demais'} {/* //TODO: mensagem aqui se pesso passar 2 kg */}

                        <div className="salvar-continuar-btn">
                            <button class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#productionTimeModal">{t('checkout.saveAndContinue')}</button>
                        </div>
                    </div>
                }
                <hr />
                <div className='payment-methods'>
                    <div>
                        <span className='checkout-title'>{t('checkout.payment')}</span>
                        {!showPaymentMethods
                            ? <img className="shopping-bag" src="../../../assets/icons/chevron-down-line.svg" />
                            : <img className="shopping-bag" src="../../../assets/icons/chevron-up-line.svg" />}

                    </div>
                    {showPaymentMethods &&
                        <div className='payment-options'>
                            <PaypalUi
                                currentCurrency={props.currentCurrency}
                                totalCarrinho={(Number(getTotalCartValue()) + Number(shippingPrice)).toFixed(2)}
                                doSaveOrder={(proviterOrderId) => DoSaveOrder(proviterOrderId)} />
                        </div>
                    }
                </div>
            </div>

            <div className='right-side-cart'>
                <span className='checkout-title'>{t('checkout.inYourBag')}</span>
                <br /><br />

                {cartItems.map(e => {
                    return (<>
                        <div>
                            <div>
                                <img className='item-cart-image' src={e.photo} />
                            </div>
                            {e.quantity} x {e.name} {e.variationName ? ` - ${e.variationName}` : ''}
                        </div>

                        <hr />
                    </>)
                })}

                <div>
                    {t('checkout.products')}: {Number(getTotalCartValue()).toFixed(2)} {props.currentCurrency}
                    <br />
                    {t('checkout.shipping')}:  {shippingPrice} {props.currentCurrency}
                    <br />
                    Total: {(Number(getTotalCartValue()) + Number(shippingPrice)).toFixed(2)}

                </div>
            </div>
        </div>
        </Suspense>
    );
}

export default Checkout;


const abrePanel = () => {

    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.display === "block") {
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        });
    }
}
import { useEffect, useState } from 'react';
import './Cart.css';
import { BrowserRouter as Router, Route, Link, Routes, useFetcher } from 'react-router-dom';
import PaypalUi from '../../components/Payments/Paypal/paypalUi';
import { GetAllExchangeRates } from './api/CartApi';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';


function Cart(props) {
    const {t, i18n} = useTranslation();
    const localStorageCartKey = "rafaela-duarte-store-cart";
    const localStorageClientTokenKey = "rafaela-duarte-store-token";
    const [userToken, setUserToken] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [exchangeRates, setExchangeRates] = useState([]);
    const [rateByCurrency, setRateByCurrency] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [currencySymbol, setCurrencySymble] = useState("");

    useEffect(() => {
        getExchangeRates();
        setUserToken(getUserToken());
        if (userToken == '') {
            getCart();
        } else {
            // TODO: vai ao banco buscar o carrinho
        }
        console.log(props.currentCurrency);
    }, []);

    const getCart = () => {
        var cart = localStorage.getItem(localStorageCartKey) == null
            ? []
            : JSON.parse(localStorage.getItem(localStorageCartKey));

        setCartItems(getDistinctProducts(cart));
    }

    const getExchangeRates = async () => {
        let rates = await GetAllExchangeRates().then(e => {
            setExchangeRates(e);
        })
    };

    useEffect(() => {
        var result = exchangeRates.filter(e => e.Code == `${props.currentCurrency}`)[0]?.ConversionFromEuro;
        if (result != null) {
            setRateByCurrency(result);
        }
        getCart();
        getCurrencySymbol();
    }, [props.currentCurrency, rateByCurrency, exchangeRates])


    const getDistinctProducts = (arrayOfProducts) => {
        let result = [];
        arrayOfProducts.forEach(function (a) {
            // if (!this[a.id]) {
                this[a.id] = { id: a.id, name: a.name, quantity: a.quantity, 
                    category: a.category, sellPrice: Number(a.sellPrice) * Number(rateByCurrency), 
                    photo: a.photo, variationName: a.variationName };
                result.push(this[a.id]);
            // } else {
            //     this[a.id].quantity += a.quantity;
            //     this[a.id].total += this[a.id].quantity * Number(this[a.id].sellPrice);
            // }
        }, Object.create(null));

        return result;
    }

    const getUserToken = () => {
        return localStorage.getItem(localStorageClientTokenKey) ?? '';
    }

    const checkUserLoggedIn = () => {

        window.location.href = "/checkout"
        return;

        if (userToken == "") { //TODO: checar aqui se o token é valido antes de redirecionar pro checkout caso o token exista no local storage
            window.location.href = "/login"
        }
        else {
            window.location.href = "/checkout"
        };
    }

    const removeItemCart = (indexId) => {
        //TODO: Add modal aqui
        var cart = JSON.parse(localStorage.getItem(localStorageCartKey));
        cart.splice(indexId, 1);

        localStorage.setItem(localStorageCartKey, JSON.stringify(cart));
        setCartItems(getDistinctProducts(cart));
    }

    const getCurrencySymbol = () =>{
        switch (props.currentCurrency) {
            case 'EUR':
                setCurrencySymble("€");
              break;
              case 'BRL':
                setCurrencySymble("R$");
              break;
              case 'USD':
                setCurrencySymble("$");
              break;
            default:
                setCurrencySymble("€");
    }
}

const confirmationProductionTimeModal = () => {


    return (<div class="modal fade" id="productionTimeModal"  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-body">
                    Devido ao elevado número de pedidos, 
                    {/* nosso temo estimado de produção é de <b> {currentProductionDate} </b> dias úteis. */}
                </div>
                <div class="modal-footer">
                    {/* <button type="button" class="btn btn-dark" data-bs-dismiss="modal" onClick={() => showPaymentMethodsSection()}>Estou ciente</button> */}
                </div>
            </div>
        </div>
    </div>)
}


    return (
        <Suspense fallback="...loading">
            {/* {confirmationProductionTimeModal()} */}
        <div className="cart-page">
            <table className="table">
                <thead className="table-dark">
                    <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col"> {t('cart.product')} </th>
                        {/* <th scope="col">Image</th> */}
                        <th scope="col"> {t('cart.price')}</th>
                        <th scope="col">Un</th>
                        <th scope="col">Total</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>

                    {cartItems && cartItems.map((e, i) => {
                        return (
                            <tr className='cart-items-list' key={i}>
                                {/* <th scope="row">1</th> */}
                                <td>
                                    <div className='product-name-image'>
                                        <Link to={`${process.env.REACT_APP_LOJA_BASE_URL}/product/${e.category}/${e.id}`}>
                                            <div className='product-name'>
                                                {e.name} {e.variationName != '' && ` - ${e.variationName}`}
                                            </div>
                                        <div className='product-image'>
                                            <img className='item-cart-image' src={e.photo} />
                                        </div>
                                        </Link>
                                    </div>
                                </td>
                                {/* <td className='cart-row'> <img className='item-cart-image' src={e.photo} /></td> */}
                                <td className='cart-row'>{currencySymbol}{e.sellPrice.toFixed(2)}</td>
                                <td className='cart-row'>{e.quantity}</td>
                                <td className='cart-row'> {currencySymbol}{(e.quantity * e.sellPrice).toFixed(2)}</td>
                                <td className='cart-row'  onClick={() => removeItemCart(i)}>
                                <div className='remove-item-trash'>
                                    <img className="shopping-bag" src="../../../assets/icons/trash3.svg" />
                                </div>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>

            <div className='finalizar-compra'>
                {/* <Link to="/checkout"> */}
                <button type="button" onClick={() => checkUserLoggedIn()} className="btn btn-dark">{t('cart.goToPayment')}</button>
                {/* </Link> */}
            </div>
            {/* <PaypalUi/> */}
        </div>
        </Suspense>
    );
}

export default Cart;


import './footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
  const {t, i18n} = useTranslation();
  return (
    <div className="footer-section">
      <div className='div-content'>

       <div className='logo-details'>
        <div className='logo-image'>
          <img className="img-logo-footer" src="../../assets/images/logo_rd.png" />
        </div>
        {/* <div>
          <span>Política de troca e devolução</span>
        </div>
        <div>
          <span>Política de Privacidade</span>
        </div> */}
       </div>

       <div className='false-conosco'>
        <div className='section-name'>{t("footer.contactUs")}</div>
        <div className='fale-conosco-email'>loja@rafaeladuartedesigner.com</div>
        <div className='fale-conosco-telefone'>(+351) 910 561 912</div>
        <div className='instagram-atelie'>
          <a href='https://www.instagram.com/rafaeladuarteatelier/' target='_blank'>
            <img className='instagram-icons' src='../../assets/icons/instagram.svg'/>
          </a>
        </div>
      </div>

      <div className='subscription'>
        <div className='sucbriction-title'>{t("footer.subscriveOurNewsLetter")}</div>
        <div className='newsletter-email'>
          <form>

            <input type='email' placeholder={t("footer.comingSoon")} disabled></input>
            <button>Subscrever</button>
          </form>

        </div>
      </div>
       
      </div>
      <div className='payments-rights'>
        <div className='payment-cards'> 
          <img src="../../assets/images/payment_cards.png"></img>
        </div>
        <div className='site-rights'>Rafaela Duarte Designer © 2024 - {t("footer.allRightsReserved")}			                                        			                                    </div>
      </div>
    </div>
  );
}

export default Footer;

export async function GetAllExchangeRates(currencyCode) {
    const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/getexchangerates`;
      const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: `currencyCode=${currencyCode}`,
        });
      const result = await response.json();
      return result;
  }

  export async function CreateOrGetCartIdIfNotExists(cartId) {
    const url = `${process.env.REACT_APP_LOJA_SERVICE_BASE_URL}/get-or-create-by-id`;
    const country = "brs"; //TODO: Passar o country to session storage;
    const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        // body: `id=${id}&country=${country}&currencyCode=${currencyCode}`,
      });
    const result = await response.json();
    return result;
  }

  export default GetAllExchangeRates;  